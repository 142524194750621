html, body {
    margin: 0px;
    padding: 0px;
}

body{
  background-color: white;
  
}

/* MAIN BODY STYLE */

.formWrap{
  display: block;
  margin-top: 35px;
  margin-bottom: 35px;
  text-align: center;
  color:#fff;
}


form {
  border: 4px solid #f1f1f1;
  display: inline-block;
  text-align: center;
  width: 25%;
}

@media screen and (max-width: 800px) {
  form {
    width: 75%;
  }
}


/* Add some padding and a background color to containers */
.container {
  display: block;
  padding: 15px;
  background-color: MidnightBlue;
}

.register{
  display: block;
  padding: 15px;
  background-color: MidnightBlue;

}

.register a{
  color: white;
  text-decoration: none;
}

.red-text{
  color: DarkRed;
}

/* Style the input elements and the submit button */
input[type=text], input[type=submit], input[type=password]  {
  width: 70%;
  padding: 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Add margins to the checkbox */
input[type=checkbox] {
  margin-top: 16px;
}

/* Style the submit button */
input[type=submit] {
  background-color: MidnightBlue;
  color: white;
  border: none;
}

input[type=submit]:hover {
  opacity: 0.8;
}
