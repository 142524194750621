
.head{
  z-index: 9999;

}

.rightAl .icon {
  display: none;
}

/* TOP TEXT HEADER */
.headertext{
  height: 150pt;
  width: 100%;
  display:table;
  background-color: black;
  /* background-image:url(resources/back.gif); */
}

.htext{
  color: white;
  position: relative;
  text-align: center;
  vertical-align:middle;
  display:table-cell;
}

.small{
  font-size: 12pt;
}


/* NAV BAR */
.navBarParent{
  line-height: 20px;
  text-align: center;
  height: 40px;
  background-color: black;
}

.link{
  margin: 25px;
  color: white;
  text-decoration: none;
  background-color: none;
  /* font-size: 1.5vw; */
}

.navBarParent a:hover {
  color: purple;
}

.leftAl{
  display: inline;
  float: left;
  margin-left: 2.0px;
}

.center{
  display: inline;
}


.link-small{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  color: white;
  text-decoration: none;
  background-color: none;
}

.rightAl{
  display: flex;
  justify-content: end;
  margin-right: 2px;
  background-color: black;
  /* position: relative */
}
.rightAl a{
  color: white;
}
.rightAl a:hover {
  color: purple;
}

.socialMedia{
  display: block;
  position: absolute;
  right: 0;
}

.social{
  width: 15%;
}

/*
// @media screen and (max-width: 600px) {
//   .center a:not(:first-child) {display: none;}
//   .navBarParent a.icon {
//     float: right;
//     display: block;
//     margin-right: 5.0px;
//   }
//
//   .navBarParent{
//     text-align:left;
//   }
//
//   .rightAl a:not(:nth-child(2)) {
//     display: none;
//   }
//
//   .leftAl a{
//     display: none;
//   }
//
//
//
// @media screen and (max-width: 600px) {
//   .navBarParent.responsive {
//     position: relative;
//     padding-bottom: 1pt;
//     height: auto;
//   }
//   .navBarParent.responsive a.icon {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
//   .navBarParent.responsive a {
//     margin: 0px;
//     padding: 25px;
//     float: none;
//     display: block;
//     text-align: left;
//     margin-top: 0px;
//   }
//
//   .navBarParent.responsive a.link:nth-child(even){
//     background-color: SteelBlue;
//   }
//
//   #leftLink a.link{
//     background-color: SteelBlue;
//   }
*/
/*});
*/
